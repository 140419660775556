#app-container {
  flex: 1 1 auto;
  display: flex;
  background-size: 100%;
}

.app-container-content {
  margin: 10px 24px;
  position: relative;
}


.active-status {
  display: flex;
  border-radius: 24px;
  width: 70px;
  background-color: rgba(153, 188, 0, 0.5);
  padding: 2px 14px;
  margin-right: auto;
  box-sizing: border-box;
  text-align: left;
  font-size: 14px;
  margin-left: 20px;
  color: var(--stannah-ok);
}