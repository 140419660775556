.add-customer-lift-confirm-dialog-content {
  width: 480px;
  padding: 20px 20px 20px 20px;
}

.add-customer-lift-confirm-dialog-header {
  color: var(--stannah-bordeux);
}

.add-customer-lift-confirm-serial-list {
  max-height: 200px;
  overflow-y: auto;

  & > div {
    line-height: 24px;
    border-bottom: 1px solid var(--stannah-web-pale-grey);
    padding: 10px 0px;
    font-weight: 500;
    font-size: 18px;
    color: var(--stannah-mid-grey);

    & b {
      line-height: 32px;
      font-weight: 800;
    }
  }
}
