.dropdown {
  height: 50px;
  min-width: 50px;
  position: relative;
  display: inline-block;
  overflow: visible;
}

.dropdown-button {
  background-color: transparent;
  border: none;
  color: var(--stannah-black);
  padding: 16px;
  font-size: 16px;
  border: none;
  cursor: pointer;
}

.dropdown-button:hover {
  color: var(--stannah-bordeux);
}

.dropdown-button :disabled {
  pointer-events: none;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: var(--stannah-grey);
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content button {
  color: var(--stannah-black);
  width: 100%;
  text-align: left;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  border: none;
}

.dropdown-content button:hover {
  color: var(--stannah-bordeux);
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown:hover .dropdown-button {
  color: var(--stannah-bordeux);
}
