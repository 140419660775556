.page {
  display: flex;
  flex-direction: column;
  background-color: transparent;

  & > header {
    color: var(--stannah-bordeux);
    height: 150px;
    display: flex;
    flex-direction: row;

    & .page-title-area {
      flex-grow: 1;

      & > h1 {
        font-size: 36px;
        line-height: 44px;
        font-weight: 500;
        margin-bottom: 3px;
      }

      & > h2 {
        color: var(--stannah-dark-grey);
        font-size: 14px;
        line-height: 20px;
        margin-top: 0px;
      }
    }

    & .page-back {
      border: 0px;
      padding-right: 24px;
      padding-bottom: 36px;
      background-color: transparent;
    }

    & .page-button-area {
      flex-grow: 0;
      flex-shrink: 0;
    }
  }
}

.page-breadcrumb {
  display: flex;
  gap: 12px;

  & > span:last-child {
    color: var(--stannah-mid-grey);
  }
}
