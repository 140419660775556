.tenant-form-details-page {
  display: flex;
  height: 100%;
  flex-direction: column;

  & input[name="id"] {
    width: 200px;
  }

  & input[name="name"] {
    width: 467px;
  }

  & input[name="businessId"] {
    width: 200px;
  }

  & input[name="address"] {
    width: 467px;
  }

  & input[name="phoneNr"] {
    width: 467px;
  }

  & input[name="email"] {
    width: 467px;
  }

  & button {
    position: relative;
    border-radius: 30px;
    border: 2px solid var(--stannah-teal);
    box-sizing: border-box;
    height: 60px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 15px 35px;
    text-align: center;
    font-size: 18px;
    color: var(--stannah-teal);
    background-color: var(--stannah-white);
  }

  & :hover {
    cursor: pointer;
  }
}

.tenant-form-details-page-inputs {
  flex-grow: 1;
  overflow-y: auto;
}
