.customer-detail-devices {
  width: 100%;
}

.customer-detail-devices-item {
  display: flex;
  flex-direction: column;
  padding: 10px 20px;
}

.customer-detail-devices-item-area {
  display: flex;
  flex-direction: row;
}

.customer-detail-devices-item-content {
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 12px;

  & header {
    flex-grow: 1;
    margin-bottom: 10px;

    & > h3 {
      font-size: 18px;
      margin: 0px;
      line-height: 24px;
      font-weight: 500;
      color: var(--stannah-black);
      text-align: left;
      height: 23px;
    }

    & > h4 {
      font-size: 14px;
      margin: 0px;
      line-height: 20px;
      color: var(--stannah-web-grey);
      text-align: left;
      height: 20.4px;
    }
  }
}

.customer-detail-devices-item-status {
  border-radius: 24px;
  background-color: rgba(154, 188, 0, 0.5);
  padding: 2px 14px;
  margin-right: auto;
  box-sizing: border-box;
  text-align: left;
  font-size: 14px;
  color: var(--stannah-ok);
}

.customer-detail-devices-item-btn {
  flex-grow: 0;
  flex-shrink: 0;
  padding: 12px;
  margin: auto 10px;
}

.customer-detail-devices-item-line {
  height: 1px;
  width: 100%;
  margin-top: 12px;
  background-color: var(--stannah-web-pale-grey);
}
