.formfield {
  width: 100%;
  height: 86px;
  text-align: left;
  font-size: 18px;
  color: var(--stannah-dark-grey);
  display: inline-block;
}

.formfield label {
  top: 1px;
  left: 0px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 800;
}

.formfield input {
  height: 60px;
  font-size: 18px;
  padding: 12px 20px;
  line-height: 24px;
  font-weight: 500;
  display: flex;
  align-items: center;
  border-radius: 6px;
  background-color: var(--stannah-white);
  border: 2px solid var(--stannah-mid-grey);
  box-sizing: border-box;
}

.formfield input:invalid {
  border-color: var(--stannah-error);
}

.formfield input[disabled] {
  --input-border: var(--stannah-web-light-grey);
  background-color: var(--stannah-web-light-grey);
  cursor: not-allowed;
}

.formfield-hint-text {
  height: 20px;
  top: 20px;
  font-size: 14px;
  line-height: 20px;
  color: var(--stannah-web-grey);
  text-align: left;
}

.formfield-optional {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  color: var(--stannah-web-grey);
  text-align: right;
  display: inline-block;
  padding-left: 100px;
  font-weight: 300;
}
