.tenant-form-admins-page {
  display: flex;
  height: 100%;
  flex-direction: column;


  & :hover {
    cursor: pointer;
  }

  & input[name="email"] {
    width: 467px;
  }
}

.tenant-form-admins-page-creation-row {
  display: flex;
  justify-content: space-between;
  align-items: center;

  & h3 {
    font-size: 18px;
    line-height: 24px;
    font-weight: 800;
    margin-bottom: 0px;
    color: var(--stannah-dark-grey);
  }

  & h4 {
    font-size: 14px;
    line-height: 20px;
    margin-top: 0px;
    color: var(--stannah-web-grey);
  }

  & > button {
    float: right;
    text-wrap: nowrap;
  }
}

.tenant-form-admins-page-users {
  padding-top: 5px;
  flex-grow: 1;
  overflow-y: auto;
}

.tenant-form-admins-page-user-item {
  display: flex;
  align-items: center;

  & div {
    flex: 1;
  }
}

.tenant-form-admins-page-email {
  flex-grow: 1;
  margin-bottom: auto;
}

.tenant-form-admins-page-delete-button {
  top: 22px;
  left: 12px;
  border: none;
  background-color: transparent;
  margin: auto;
  padding: 30px 10px 10px 10px;

  &:hover {
    cursor: pointer;
  }

  & .delete-icon {
    width: 24px;
  }
}

