.customer-form {
  height: 100%;

  & form {
    height: 100%;
  }
}
.customer-form-contact-page,
.customer-form-customer-page {
  display: flex;
  height: 100%;
  flex-direction: column;

  & input[name="id"] {
    width: 200px;
  }

  & input[name="firstName"] {
    width: 336px;
  }

  & input[name="lastName"] {
    width: 336px;
  }

  & input[name="organization"] {
    width: 467px;
  }

  & .c-vat {
    margin-left: 20px;
  }

  & input[name="vat"] {
    width: 200px;
  }

  & input[name="phoneNr"] {
    width: 467px;
  }

  & input[name="email"] {
    width: 467px;
  }

  & input[name="address"] {
    width: 467px;
  }

  & .c-apartment-nr {
    margin-left: 20px;
  }

  & input[name="apartmentNr"] {
    width: 200px;
  }

  & input[name="city"] {
    width: 467px;
  }

  & .c-zip-code {
    margin-left: 20px;
  }

  & input[name="zipCode"] {
    width: 200px;
  }

  & input[name="region"] {
    width: 336px;
  }

  & input[name="country"] {
    width: 336px;
  }

  & button {
    position: relative;
    border-radius: 30px;
    border: 2px solid var(--stannah-teal);
    box-sizing: border-box;
    height: 60px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 15px 35px;
    text-align: center;
    font-size: 18px;
    color: var(--stannah-teal);
    background-color: var(--stannah-white);
  }

  & button:disabled {
    --input-border: var(--stannah-web-light-grey);
    background-color: var(--stannah-web-light-grey);
    cursor: not-allowed;
  }

  & :hover {
    cursor: pointer;
  }

  & #next_button,
  & #back_button {
    float: right;
  }

  & #submit_button {
    float: right;
    color: var(--stannah-white);
    background-color: var(--stannah-teal);
    margin-left: 20px;
  }
}

.customer-form-inputs {
  flex-grow: 1;
  overflow-y: auto;
}

.customer-form-creation-row {
  display: flex;
  justify-content: space-between;
  align-items: center;

  & h3 {
    font-size: 18px;
    line-height: 24px;
    font-weight: 800;
    margin-bottom: 0px;
    color: var(--stannah-dark-grey);
  }

  & h4 {
    font-size: 14px;
    line-height: 20px;
    margin-top: 0px;
    color: var(--stannah-web-grey);
  }

  & button {
    float: right;
  }
}

.customer-form-creation-input-row {
  width: 90%;
  display: flex;
}

.customer-form-contact-users {
  display: flex;

  & input[name*="displayName"] {
    width: 137px;
  }

  & input[name*="firstName"] {
    width: 137px;
  }

  & input[name*="lastName"] {
    width: 137px;
  }

  & input[name*="phoneNr"] {
    width: 230px;
  }

  & .delete-contact-button {
    top: 22px;
    left: 12px;
    border: none;

    &:hover {
      cursor: pointer;
    }

    & .delete-icon {
      width: 24px;
    }
  }
}

.customer-form-errors {
  flex-grow: 1;
  padding: 20px;
  align-content: end;
  font-size: 14px;
  color: var(--stannah-web-grey);
}
