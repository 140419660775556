.side-modal-button-bar {
  bottom: 0px;
  padding-top: 20px;
  height: 90px;
  flex-grow: 0;
  display: flex;
  gap: 20px;

  & button {
    color: var(--stannah-teal);
    background-color: var(--stannah-white);
    border: 2px solid var(--stannah-teal);
  }

  & #cancel_admin_button {
    margin-right: auto;
  }

  & #submit_button {
    color: var(--stannah-white);
    background-color: var(--stannah-teal);
  }

  & #submit_button:disabled {
    color: var(--stannah-white);
    background-color: var(--stannah-grey);
    border: 2px solid var(--stannah-grey);
  }

}
