.add-customer-lifts-form {
  height: 100%;
  display: flex;

  & form {
    height: 100%;
    display: flex;
  }
}

.add-customer-lifts-form-page {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-y: auto;
}

.add-customer-lifts-form-button-panel {
  bottom: 0px;
  padding-top: 20px;
  height: 90px;
  flex-grow: 0;
  display: flex;
  gap: 20px;
  justify-content: flex-end;

  & button {
    display: inline-block;
  }
}

.add-customer-lifts-form-creation-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 36px;
  padding-bottom: 32px;

  & > h3 {
    font-size: 18px;
    line-height: 24px;
    font-weight: 800;
    margin-bottom: 0px;
    color: var(--stannah-dark-grey);
  }

  & > h4 {
    font-size: 14px;
    line-height: 20px;
    margin-top: 0px;
    color: var(--stannah-web-grey);
  }

  & > div {
    font-weight: 800;
    font-size: 14px;
    line-height: 20px;
    margin-top: 0px;
    color: var(--stannah-web-grey);
  }

  & > button {
    float: right;
    text-wrap: nowrap;
  }
}

.add-customer-lifts-form-creation-input-row {
  display: flex;
  gap: 10px;

  & input[name="serialNumber"] {
    width: 100%;
  }

  & input[name="nickname"] {
    width: 100%;
  }
}

.add-customer-lifts-form-delete-btn {
  top: 22px;
  left: 12px;
  border: none;
  background-color: transparent;
  margin: auto;
  padding: 30px 10px 10px 10px;

  &:hover {
    cursor: pointer;
  }

  & .delete-icon {
    width: 24px;
  }
}

.add-customer-lifts-form-errors {
  flex-grow: 1;
  padding: 20px;
  align-content: end;
  font-size: 14px;
  color: var(--stannah-web-grey);
}
