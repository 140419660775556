.sim-settings-form {
  display: flex;
  height: 100%;
  flex-direction: column;

  & form {
    height: 100%;
    display: flex;
  }
}

.sim-settings-form-lines {
  padding-top: 5px;
  flex-grow: 1;
  overflow-y: auto;
  padding-left: 40px;
  padding-right: 100px;

  
  & .formfield input {
    width: 100%;
  }

}
